import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RefundPolicy = () => {
  const renderContent = (content) => {
    return content.split('\n').map((line, i) => (
      <Typography key={i} paragraph>
        {line.trim()}
      </Typography>
    ));
  };

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
          Refund Policy
        </Typography>
        
        <Typography paragraph color="text.secondary" sx={{ mb: 4 }}>
          VotSim (hereinafter referred to as "the Company") operates the following refund policy regarding paid services.
        </Typography>

        {[
          {
            title: "1. Refund Eligibility",
            content: `You may request a refund in the following cases:
            - If you haven't used the service at all after payment
            - If normal service use is impossible due to service errors
            - If significant service disruption occurs due to the Company's fault
            - If other withdrawal reasons arise according to relevant laws`
          },
          {
            title: "2. Refund Request Period",
            content: `- Within 7 days of payment: Full refund available
            - After 7 days of payment: Refund minus the amount for actual service usage days
            - For recurring payments: Cancellation of next payment if unsubscribed before the next billing date`
          },
          {
            title: "3. Refund Exclusions",
            content: `Refunds may be restricted in the following cases:
            - Simple change of mind after actually using the service
            - History of using the service through fraudulent methods
            - Service suspension due to violation of relevant laws and terms of use
            - Special price purchases using event benefits or promotion codes`
          },
          {
            title: "4. Refund Process",
            content: `1. How to Request a Refund
            - Submit refund request through customer support email
            - Required information: Payer's name, payment date, payment amount, reason for refund
            
            2. Processing Period
            - Processing within 3-5 business days after receiving the refund request
            - Additional processing time may be required depending on the payment method
            
            3. Refund Amount Calculation
            - Daily rate calculation for unused period
            - Refund based on actual payment amount if discounts were applied`
          },
          {
            title: "5. Special Refund Policies",
            content: `1. Service Termination
            - Full refund for remaining period if service is terminated due to company circumstances
            
            2. Payment Errors
            - Duplicate payment due to customer error: Full refund for unused duplicate payment
            - Payment errors due to system issues: Full refund`
          },
          {
            title: "6. Refund Methods",
            content: `Refunds will be processed through the following methods:
            - Credit card payments: Card authorization cancellation
            - Bank transfers: Transfer to refund account
            - Other payment methods: According to each payment method's refund policy`
          }
        ].map((section, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontWeight="bold">
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderContent(section.content)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Container>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
};

export default RefundPolicy;