import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { Box, Card, CardContent, Typography, Button, TextField, Grid, Alert } from '@mui/material';

function Profile() {
  const [userData, setUserData] = useState({
    displayName: '',
    email: '',
    membershipType: 'Free',
    usageCount: 0,
    premiumCredits: 0,
    joinDate: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.currentUser) return;

      // Basic user data setup moved outside of try block
      const basicUserData = {
        displayName: auth.currentUser.displayName || 'User',
        email: auth.currentUser.email,
        joinDate: auth.currentUser.metadata.creationTime,
        membershipType: 'Free',
        premiumCredits: 0,
        usageHistory: []
      };

      try {
        // Get user document from Firestore
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          // Create new user document with basic data if it doesn't exist
          try {
            await setDoc(userDocRef, basicUserData);
            setUserData({
              ...basicUserData,
              remainingUses: 5
            });
          } catch (error) {
            console.error('Failed to create new user data:', error);
            setUserData({
              ...basicUserData,
              remainingUses: 5
            });
          }
        } else {
          const userData = userDoc.data();
          
          // Calculate usage within 24 hours
          const now = new Date();
          const usageHistory = userData.usageHistory || [];
          const recentUsage = usageHistory.filter(usage => {
            const usageTime = new Date(usage);
            return (now - usageTime) < 24 * 60 * 60 * 1000;
          });

          setUserData({
            ...basicUserData,
            membershipType: userData.membershipType || 'Free',
            premiumCredits: userData.premiumCredits || 0,
            remainingUses: 5 - recentUsage.length,
            membershipExpiry: userData.membershipExpiry || null
          });
        }
        
        setNewDisplayName(auth.currentUser.displayName || '');
      } catch (error) {
        console.error('Failed to load user data:', error);
        setUserData({
          ...basicUserData,
          remainingUses: 5
        });
        setError('Failed to load profile information. Showing default information.');
      }
    };

    fetchUserData();
  }, []);

  const handleUpdateDisplayName = async () => {
    try {
      if (!newDisplayName.trim()) {
        setError('Please enter a name.');
        return;
      }

      // Update Firebase Authentication profile
      await updateProfile(auth.currentUser, {
        displayName: newDisplayName
      });

      // Update Firestore document
      await updateDoc(doc(db, 'users', auth.currentUser.uid), {
        displayName: newDisplayName
      });

      setUserData(prev => ({ ...prev, displayName: newDisplayName }));
      setIsEditing(false);
      setSuccess('Name successfully updated.');
      
      // Remove success message after 3 seconds
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error('Failed to update profile:', error);
      setError('Failed to update profile.');
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: 'auto', p: 4 }}>
      <Typography variant="h4" gutterBottom>
        My Profile
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      
      <Grid container spacing={3}>
        {/* Basic Information Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Basic Information
              </Typography>
              <Box sx={{ mt: 2 }}>
                {isEditing ? (
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={newDisplayName}
                      onChange={(e) => setNewDisplayName(e.target.value)}
                      sx={{ mb: 1 }}
                    />
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button variant="contained" onClick={handleUpdateDisplayName}>
                        Save
                      </Button>
                      <Button variant="outlined" onClick={() => {
                        setIsEditing(false);
                        setNewDisplayName(userData.displayName);
                        setError('');
                      }}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Typography variant="body1" gutterBottom>
                      Name: {userData.displayName}
                    </Typography>
                    <Button variant="outlined" onClick={() => setIsEditing(true)} sx={{ mb: 2 }}>
                      Edit Name
                    </Button>
                  </>
                )}
                <Typography variant="body1" gutterBottom>
                  Email: {userData.email}
                </Typography>
                <Typography variant="body1">
                  Join Date: {new Date(userData.joinDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Membership Information Card */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Membership Information
              </Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" gutterBottom>
                  Current Membership: {userData.membershipType}
                </Typography>
                
                {(userData.membershipType === 'Pro' || userData.membershipType === 'Basic') && userData.membershipExpiry && (
                  <Typography variant="body1" gutterBottom color="text.secondary">
                    Membership Expiry: {new Date(userData.membershipExpiry).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </Typography>
                )}

                <Typography variant="body1" gutterBottom>
                  Remaining Premium Credits: {userData.premiumCredits} uses
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Free Uses Left Today: {userData.remainingUses} uses
                </Typography>

                <Button variant="outlined" onClick={() => window.location.href='/pricing'}>
                  Change Membership
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Profile;