import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  collection,
  query,
  getDocs,
  orderBy,
  deleteDoc,
  doc,
  setDoc,
  updateDoc,
  where,
  limit,
  startAfter
} from 'firebase/firestore';
import { throttle } from 'lodash';
import {
  Box,
  Typography,
  Paper,
  Avatar,
  Button,
  Collapse,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  CircularProgress,
  TextField,
  MenuItem,
  Select,
  FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { db, auth } from '../firebase';
import VoteVisualization from './VoteVisualization';

/* 
  ---------------------
  1) 삭제 확인 다이얼로그 분리
  ---------------------
*/
function DeleteConfirmDialog({ 
  open, 
  onClose, 
  onConfirm,
  message
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      disableRestoreFocus
      keepMounted
      onBackdropClick={onClose}
    >
      <DialogTitle id="delete-dialog-title">Confirm Delete Message</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this message?
        </DialogContentText>
        <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
          {message?.question}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} color="error" variant="contained" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* 
  ---------------------
  2) 메시지 아이템 분리
  ---------------------
*/
function MessageItem({
  message,
  expanded,
  onToggleAnswer,
  onDeleteClick,
  onLike,
  userLikes,
  isOwner
}) {
  return (
    <Paper sx={{ p: 3, mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar sx={{ mr: 2 }}>
          {(message.userDisplayName || message.userEmail.split('@')[0])[0].toUpperCase()}
        </Avatar>
        <Box sx={{ flex: 1 }}>
          <Typography variant="body2" color="text.secondary">
            Shared by: {message.userDisplayName || message.userEmail.split('@')[0]}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Date: {new Date(message.sharedAt).toLocaleString()}
          </Typography>
        </Box>

        {isOwner && (
          <IconButton
            onClick={() => onDeleteClick(message)}
            size="small"
            sx={{ '&:hover': { color: 'error.main' } }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
          <IconButton
            onClick={() => onLike(message.id)}
            color={userLikes[message.id] ? 'error' : 'default'}
          >
            {userLikes[message.id] ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
          <Typography variant="body2">{message.likeCount || 0}</Typography>
        </Box>
      </Box>

      <Typography
        variant="body1"
        sx={{ fontWeight: 'bold', mb: 1, whiteSpace: 'pre-wrap' }}
      >
        Question: {message.question}
      </Typography>

      <Box sx={{ borderTop: '1px solid #e0e0e0', my: 2 }} />

      {message.isVoteSimulation && (
        <Box sx={{ width: '100%', minHeight: '300px', overflowX: 'auto', mb: 3 }}>
          <VoteVisualization
            gptResponse={message.answer}
            options={message.simulationOptions || []}
            agenda={message.question.match(/"([^"]+)"/)?.[1] || ''}
            displayName={
              message.userDisplayName || message.userEmail.split('@')[0]
            }
          />
        </Box>
      )}

      <Button onClick={() => onToggleAnswer(message.id)}>
        {expanded ? 'Hide Answer ▼' : 'Show Answer ▶'}
      </Button>

      <Collapse in={expanded} timeout="auto">
        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
          Answer: {message.answer}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigator.clipboard.writeText(message.answer);
              alert('Copied to clipboard!');
            }}
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': {
                borderColor: 'primary.dark',
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            Copy
          </Button>
        </Box>
      </Collapse>
    </Paper>
  );
}

/* 
  ---------------------
  3) 메인 컴포넌트
  ---------------------
*/
function Community() {
  const [sharedMessages, setSharedMessages] = useState([]);
  const [expandedAnswers, setExpandedAnswers] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [userLikes, setUserLikes] = useState({});
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // 검색 관련
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const searchRef = useRef('');
  const [sortOption, setSortOption] = useState('latest');

  // 메시지 키를 저장하여 React key 충돌 방지(애니메이션/리스트 안정성)
  const [messageKeys, setMessageKeys] = useState(new Map());

  // -------------------
  // 초기 로드
  // -------------------
  useEffect(() => {
    loadSharedMessages(true);
    if (auth.currentUser) {
      loadUserLikes();
    }
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        loadUserLikes();
      } else {
        setUserLikes({});
      }
    });
    return () => unsubscribe();
  }, []);

  // 메시지마다 키를 생성
  useEffect(() => {
    const newKeys = new Map();
    [...sharedMessages, ...searchResults].forEach((msg) => {
      // 이미 존재하는 키가 없으면 새로 생성
      if (!messageKeys.has(msg.id)) {
        newKeys.set(msg.id, `${msg.id}-${Date.now()}-${Math.random()}`);
      } else {
        // 기존에 존재하는 키는 그대로
        newKeys.set(msg.id, messageKeys.get(msg.id));
      }
    });
    setMessageKeys(newKeys);
  }, [sharedMessages, searchResults]);

  // -------------------
  // 무한 스크롤
  // -------------------
  const throttledHandleScroll = useMemo(
    () =>
      throttle(() => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollPosition >= documentHeight * 0.8 && !isLoading && hasMore) {
          if (isSearching && searchRef.current?.value.trim()) {
            loadMoreSearchResults();
          } else {
            loadSharedMessages(false);
          }
        }
      }, 300),
    [isLoading, hasMore, isSearching, sortOption, lastDoc]
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      throttledHandleScroll.cancel();
    };
  }, [throttledHandleScroll]);

  // -------------------
  // 메시지 불러오기
  // -------------------
  const loadSharedMessages = async (isFirstLoad = false) => {
    if (isLoading || (!hasMore && !isFirstLoad)) return;

    try {
      setIsLoading(true);
      let baseQuery = collection(db, 'shared_messages');

      let q = query(
        baseQuery,
        sortOption === 'latest'
          ? orderBy('sharedAt', 'desc')
          : orderBy('likeCount', 'desc'),
        limit(10)
      );

      if (lastDoc && !isFirstLoad) {
        q = query(q, startAfter(lastDoc));
      }

      const querySnapshot = await getDocs(q);
      const newMessages = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
        likeCount: docSnap.data().likeCount || 0
      }));

      setSharedMessages((prev) =>
        isFirstLoad ? newMessages : [...prev, ...newMessages]
      );
      setSearchResults((prev) =>
        isFirstLoad ? newMessages : [...prev, ...newMessages]
      );
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 10);

      // 검색 모드가 아니라면 isSearching = false 처리
      if (isFirstLoad) {
        setIsSearching(false);
      }
    } catch (error) {
      console.error('Failed to load messages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // -------------------
  // 좋아요 상태 로드
  // -------------------
  const loadUserLikes = async () => {
    if (!auth.currentUser) return;

    try {
      const likesRef = collection(db, 'likes');
      const q = query(likesRef, where('userId', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);

      const likes = {};
      querySnapshot.forEach((docSnap) => {
        likes[docSnap.data().messageId] = true;
      });
      setUserLikes(likes);
    } catch (error) {
      console.error('Failed to load likes:', error);
    }
  };

  // -------------------
  // 메시지 삭제
  // -------------------
  const handleDeleteClick = (message) => {
    setMessageToDelete(message);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!messageToDelete || !auth.currentUser) return;

    try {
      if (messageToDelete.userId === auth.currentUser.uid) {
        await deleteDoc(doc(db, 'shared_messages', messageToDelete.id));
        setSharedMessages((prev) =>
          prev.filter((msg) => msg.id !== messageToDelete.id)
        );
        setSearchResults((prev) =>
          prev.filter((msg) => msg.id !== messageToDelete.id)
        );
        alert('Message has been deleted.');
      } else {
        alert('You do not have permission to delete this message.');
      }
    } catch (error) {
      console.error('Failed to delete message:', error);
      alert('Failed to delete message.');
    } finally {
      setDeleteDialogOpen(false);
      setMessageToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setMessageToDelete(null);
  };

  // -------------------
  // 좋아요
  // -------------------
  const handleLike = async (messageId) => {
    if (!auth.currentUser) {
      alert('Please Sign in to like messages.');
      return;
    }
    if (!auth.currentUser.emailVerified) {
      alert(
        'Email verification required. Please verify your email before liking.'
      );
      return;
    }

    try {
      const likeId = `${messageId}_${auth.currentUser.uid}`;
      const likeRef = doc(db, 'likes', likeId);
      const messageRef = doc(db, 'shared_messages', messageId);

      // 현재 메시지를 찾아서 likeCount 파악
      const findMessageIn = (arr) => arr.find((m) => m.id === messageId);

      if (userLikes[messageId]) {
        // 이미 좋아요 -> 취소
        await deleteDoc(likeRef);
        const newLikeCount = (findMessageIn(sharedMessages)?.likeCount || 1) - 1;

        await updateDoc(messageRef, { likeCount: newLikeCount });
        setUserLikes((prev) => {
          const updated = { ...prev };
          delete updated[messageId];
          return updated;
        });
        // local state 업데이트
        setSharedMessages((prev) =>
          prev.map((m) => (m.id === messageId ? { ...m, likeCount: newLikeCount } : m))
        );
        setSearchResults((prev) =>
          prev.map((m) => (m.id === messageId ? { ...m, likeCount: newLikeCount } : m))
        );
      } else {
        // 좋아요 추가
        const newLikeCount = (findMessageIn(sharedMessages)?.likeCount || 0) + 1;
        await setDoc(likeRef, {
          userId: auth.currentUser.uid,
          messageId: messageId,
          createdAt: new Date()
        });
        await updateDoc(messageRef, { likeCount: newLikeCount });

        setUserLikes((prev) => ({ ...prev, [messageId]: true }));
        setSharedMessages((prev) =>
          prev.map((m) => (m.id === messageId ? { ...m, likeCount: newLikeCount } : m))
        );
        setSearchResults((prev) =>
          prev.map((m) => (m.id === messageId ? { ...m, likeCount: newLikeCount } : m))
        );
      }
    } catch (error) {
      console.error('Failed to process like:', error);
      alert('Failed to process like.');
    }
  };

  // -------------------
  // 검색
  // -------------------
  const handleSearch = useCallback(async () => {
    const term = searchRef.current?.value.trim().toLowerCase() || '';
    setIsSearching(!!term);

    try {
      setIsLoading(true);
      setLastDoc(null); // 검색 시 새로 시작

      if (term) {
        let q = query(
          collection(db, 'shared_messages'),
          where('searchKeywords', 'array-contains', term),
          sortOption === 'latest'
            ? orderBy('sharedAt', 'desc')
            : orderBy('likeCount', 'desc'),
          limit(10)
        );

        const querySnapshot = await getDocs(q);
        const msgs = querySnapshot.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
          likeCount: docSnap.data().likeCount || 0
        }));

        setSearchResults(msgs);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === 10);
      } else {
        // 검색어가 없으므로 전체 목록 새로 로드
        loadSharedMessages(true);
      }
    } catch (error) {
      console.error('Search failed:', error);
      alert('An error occurred while searching.');
    } finally {
      setIsLoading(false);
    }
  }, [sortOption]);

  const loadMoreSearchResults = async () => {
    if (isLoading || !hasMore) return;

    try {
      setIsLoading(true);
      const term = searchRef.current?.value.trim().toLowerCase();
      if (!term) {
        setIsSearching(false);
        return;
      }

      let q = query(
        collection(db, 'shared_messages'),
        where('searchKeywords', 'array-contains', term),
        sortOption === 'latest'
          ? orderBy('sharedAt', 'desc')
          : orderBy('likeCount', 'desc'),
        startAfter(lastDoc),
        limit(10)
      );

      const querySnapshot = await getDocs(q);
      const newMessages = querySnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
        likeCount: docSnap.data().likeCount || 0
      }));

      setSearchResults((prev) => [...prev, ...newMessages]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 10);
    } catch (error) {
      console.error('Failed to load more messages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // -------------------
  // 메시지 목록 계산
  // -------------------
  const displayedMessages = isSearching ? searchResults : sharedMessages;

  const handleToggleAnswer = (id) => {
    setExpandedAnswers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  // -------------------
  // 렌더링
  // -------------------
  return (
    <Box
      sx={{
        maxWidth: 800,
        margin: 'auto',
        mt: 5,
        p: 3
      }}
    >
      <Typography variant="h4" gutterBottom>
        Community
      </Typography>

      {/* 검색 / 정렬 UI */}
      <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
        <Box sx={{ position: 'relative', flex: 1 }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Search messages..."
            inputRef={searchRef}
            defaultValue=""
            inputProps={{
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: '70px'
              }
            }}
          />
          <FormControl
            size="small"
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              minWidth: '70px'
            }}
          >
            <Select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              displayEmpty
            >
              <MenuItem value="latest">Latest</MenuItem>
              <MenuItem value="likes">Most Liked</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button variant="outlined" onClick={handleSearch}>
          {isLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={16} color="inherit" />
              <span>Search</span>
            </Box>
          ) : (
            'Search'
          )}
        </Button>
      </Box>

      {/* 메시지 목록 */}
      {isSearching ? (
        displayedMessages.length === 0 ? (
          <Typography
            sx={{ textAlign: 'center', color: 'text.secondary', my: 2 }}
          >
            No results found.
          </Typography>
        ) : (
          <>
            {displayedMessages.map((message) => (
              <MessageItem
                key={messageKeys.get(message.id) || message.id}
                message={message}
                expanded={expandedAnswers[message.id] || false}
                onToggleAnswer={handleToggleAnswer}
                onDeleteClick={handleDeleteClick}
                onLike={handleLike}
                userLikes={userLikes}
                isOwner={message.userId === auth.currentUser?.uid}
              />
            ))}
            {!hasMore && displayedMessages.length > 0 && (
              <Typography
                sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}
              >
                All messages have been loaded.
              </Typography>
            )}
          </>
        )
      ) : (
        <>
          {sharedMessages.map((message) => (
            <MessageItem
              key={messageKeys.get(message.id) || message.id}
              message={message}
              expanded={expandedAnswers[message.id] || false}
              onToggleAnswer={handleToggleAnswer}
              onDeleteClick={handleDeleteClick}
              onLike={handleLike}
              userLikes={userLikes}
              isOwner={message.userId === auth.currentUser?.uid}
            />
          ))}
          {!hasMore && sharedMessages.length > 0 && (
            <Typography sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}>
              All messages have been loaded.
            </Typography>
          )}
        </>
      )}

      {/* 삭제 모달 */}
      <DeleteConfirmDialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        message={messageToDelete}
      />
    </Box>
  );
}

export default Community;
