// Terms.js
import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const TermsOfService = () => {
  const renderContent = (content) => {
    return content.split('\n').map((line, i) => (
      <Typography key={i} paragraph>
        {line.trim()}
      </Typography>
    ));
  };

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
          Terms of Service
        </Typography>
        
        <Typography paragraph color="text.secondary" sx={{ mb: 4 }}>
          These terms and conditions establish the rights, obligations, and responsibilities between VotSim (hereinafter referred to as "Company") and its members regarding the use of the voting simulation service.
        </Typography>

        {[
          {
            title: "Article 1 (Purpose)",
            content: `These terms and conditions aim to establish the basic matters regarding the rights, obligations, responsibilities, conditions of use, and procedures between the Company and members concerning the use of voting simulation services and related supplementary services provided by the Company.`
          },
          {
            title: "Article 2 (Definitions)",
            content: `The definitions of terms used in these terms and conditions are as follows:
            1. "Service" refers to the voting simulation and related supplementary services provided by the Company.
            2. "Member" refers to a person who has agreed to these terms and conditions and entered into a service usage contract with the Company.
            3. "Free Member" refers to a member who uses the free service.
            4. "Paid Member" refers to a member who uses the paid service.`
          },
          {
            title: "Article 3 (Service Provision and Changes)",
            content: `1. The Company provides the following services:
               - Voting simulation service
               - Community service
               - All other services that the Company develops additionally or provides to members through partnership agreements
            2. The Company may change or discontinue services, in which case prior notice will be given to members.`
          },
          {
            title: "Article 4 (Service Fees)",
            content: `1. Services are divided into free services and paid services.
            2. The usage fees and payment methods for paid services follow the content specified in the respective services.
            3. The Company may change the usage fees for paid services, and when changed, it will be announced according to the terms and conditions.`
          },
          {
            title: "Article 5 (Member Obligations)",
            content: `Members shall not engage in any of the following actions:
            1. Registering false information when applying for membership or making changes
            2. Impersonating others
            3. Modifying information posted by the Company
            4. Transmitting or posting information other than that specified by the Company
            5. Infringing on intellectual property rights, including copyrights of the Company and other third parties
            6. Damaging the reputation of or interfering with the business of the Company and other third parties`
          },
          {
            title: "Article 6 (Service Suspension and Disclaimer)",
            content: `1. The Company may suspend service provision in the following cases:
               - Unavoidable circumstances such as facility maintenance
               - When telecommunications service is suspended by telecommunications carriers as stipulated in the Telecommunications Business Act
               - Other cases of force majeure
            2. The Company is not liable for damages caused by service suspension.`
          }
        ].map((section, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontWeight="bold">
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderContent(section.content)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Container>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
};

export default TermsOfService;