import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';
import { Star } from 'lucide-react';

const PremiumDialog = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', pb: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Star size={48} color="#2196f3" fill="#2196f3" />
        </Box>
        <Typography 
          variant="div" 
          sx={{ 
            fontSize: '1.5rem',
            fontWeight: 'bold'
          }}
        >
          Upgrade to Premium!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 3 }}>
          With Pro membership, you can run<br/>
          up to 100 simulations using advanced AI models.<br/>
          Get more accurate results with more simulations.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column', gap: 1, px: 3, pb: 3 }}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            navigate('/pricing');
            onClose();
          }}
          sx={{ 
            borderRadius: 2,
            py: 1.5
          }}
        >
          Learn About Pro Membership
        </Button>
        <Button
          fullWidth
          variant="text"
          onClick={onClose}
          sx={{ 
            borderRadius: 2,
            color: 'text.secondary'
          }}
        >
          Maybe Later
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PremiumDialog;