import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { auth, db } from '../firebase';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  startAfter,
  limit,
  orderBy,
  getDoc,
  updateDoc,
  setDoc
} from 'firebase/firestore';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { throttle } from 'lodash';

import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Collapse,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import PremiumDialog from './PremiumDialog';
import DailyUsageCoins from './DailyUsageCoins';
import VoteVisualization from './VoteVisualization';

/* 
  -----------------------------
  1) 작은 컴포넌트(모달, 메세지Item 등) 정의
  -----------------------------
*/

function DeleteConfirmDialog({
  open,
  onClose,
  onConfirm,
  isSharedMessage,
  messageToDelete
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus
      disableRestoreFocus
      keepMounted
      onBackdropClick={onClose}
    >
      <DialogTitle id="alert-dialog-title">Confirm Delete Message</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this message?
        </DialogContentText>
        {isSharedMessage && (
          <Typography color="warning.main" sx={{ mt: 2 }}>
            ⚠️ This message is shared in the community.
            Deleting it will also remove it from the community.
          </Typography>
        )}
        <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
          {messageToDelete?.question}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="error" variant="contained" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** 각 메시지 목록에서 반복 렌더링되는 아이템 **/
function MessageItem({
  message,
  onToggleAnswer,
  expanded,
  onCopyAnswer,
  onDeleteClick,
  onShare,
  sharingMessageId,
  isOwner, // 메시지 소유자 여부
  currentUserDisplayName
}) {
  const hasSimulation = message.isVoteSimulation;

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography
        variant="body1"
        sx={{ fontWeight: 'bold', mb: 1, whiteSpace: 'pre-wrap' }}
      >
        Question: {message.question}
      </Typography>
      <Box sx={{ borderTop: '1px solid #e0e0e0', my: 2 }} />

      {/* 투표 시뮬레이션 시각화 */}
      {hasSimulation && (
        <Box
          sx={{
            width: '100%',
            minHeight: '300px',
            overflowX: 'auto',
            mb: 3
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%'
            }}
          >
            <VoteVisualization
              gptResponse={message.answer}
              options={message.simulationOptions || []}
              agenda={message.question.match(/"([^"]+)"/)?.[1] || ''}
              displayName={currentUserDisplayName || 'Anonymous'}
            />
          </Box>
        </Box>
      )}

      <Button onClick={() => onToggleAnswer(message.documentId)}>
        {expanded ? 'Hide Answer ▼' : 'View Answer ▶'}
      </Button>

      <Collapse in={expanded} timeout="auto">
        <Typography variant="body1" sx={{ mb: 1, whiteSpace: 'pre-wrap' }}>
          Answer: {message.answer}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
          <Button
            variant="outlined"
            onClick={() => onCopyAnswer(message.answer)}
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': {
                borderColor: 'primary.dark',
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            Copy
          </Button>
        </Box>
      </Collapse>

      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
      >
        <Typography variant="caption" color="text.secondary">
          {message.timestamp && new Date(message.timestamp).toLocaleString()}
        </Typography>
        {isOwner && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={() => onDeleteClick(message)}
              size="small"
              sx={{
                '&:hover': {
                  color: 'error.main'
                }
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
            <Button
              variant="outlined"
              size="small"
              onClick={() => onShare(message)}
              disabled={sharingMessageId === message.documentId}
              sx={{
                minWidth: '80px',
                position: 'relative'
              }}
            >
              {sharingMessageId === message.documentId ? (
                <>
                  <CircularProgress
                    size={16}
                    sx={{
                      position: 'absolute',
                      left: '50%',
                      marginLeft: '-8px'
                    }}
                  />
                  <span style={{ visibility: 'hidden' }}>Share</span>
                </>
              ) : (
                'Share'
              )}
            </Button>
          </Box>
        )}
      </Box>
    </Paper>
  );
}

/* 
  -----------------------------
  2) 주요 로직(채팅/시뮬레이션) 컴포넌트
  -----------------------------
*/
function Chat() {
  const navigate = useNavigate();

  /** -----------------
   *  State & Refs
   * ----------------- */
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [expandedAnswers, setExpandedAnswers] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [searchHasMore, setSearchHasMore] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const searchRef = useRef();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [isSharedMessage, setIsSharedMessage] = useState(false);

  const [sharingMessageId, setSharingMessageId] = useState(null);

  // 시뮬레이션 관련
  const [step, setStep] = useState(1);
  const [agenda, setAgenda] = useState('');
  const [optionCount, setOptionCount] = useState('');
  const [options, setOptions] = useState([]);
  const [peopleCount, setPeopleCount] = useState('');
  const [people, setPeople] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [sourceIndex, setSourceIndex] = useState(null);
  const [lastCheckedIndex, setLastCheckedIndex] = useState(null);
  const [isSimulationReady, setIsSimulationReady] = useState(false);
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const [isSimulationLoading, setIsSimulationLoading] = useState(false);
  const [usePremium, setUsePremium] = useState(false);

  const [isPro, setIsPro] = useState(false);
  const [premiumCredits, setPremiumCredits] = useState(0);
  const [remainingUses, setRemainingUses] = useState(5);
  const [showPremiumDialog, setShowPremiumDialog] = useState(false);

  // 각 스텝 입력을 위한 ref
  const agendaRef = useRef();
  const optionCountRef = useRef();
  const peopleCountRef = useRef();
  const optionsRef = useRef([]);

  /** -----------------
   *  Firebase Load Logic
   * ----------------- */
  // 초기 로드
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        // 이메일 인증 확인
        if (!currentUser.emailVerified) {
          setMessages([]);
          return;
        }
        // 토큰 갱신
        await currentUser.getIdToken(true);
        // 채팅 히스토리 로딩
        loadHistory(true);
      } else {
        setMessages([]);
      }
    });
    return () => unsubscribe();
  }, []);

  // 사용자 정보 로드(멤버십 등)
  useEffect(() => {
    const loadUserData = async () => {
      if (!auth.currentUser || !auth.currentUser.emailVerified) return;

      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setPremiumCredits(userData.premiumCredits || 0);

        // 멤버십 만료 체크
        const now = new Date();
        const expiry = userData.membershipExpiry
          ? new Date(userData.membershipExpiry)
          : null;
        const isPremiumActive =
          (userData.membershipType === 'Pro' ||
            userData.membershipType === 'Basic') &&
          expiry &&
          now < expiry;

        if (!isPremiumActive &&
            (userData.membershipType === 'Pro' ||
             userData.membershipType === 'Basic')
        ) {
          // 만료된 경우 Free로 변경
          await updateDoc(userDocRef, {
            membershipType: 'Free',
            premiumCredits: 0
          });
          setIsPro(false);
        } else {
          setIsPro(isPremiumActive);
        }

        // 24시간 이내 사용량 계산
        const usageHistory = userData.usageHistory || [];
        const recentUsage = usageHistory.filter((usage) => {
          const usageTime = new Date(usage);
          return now - usageTime < 24 * 60 * 60 * 1000;
        });
        setRemainingUses(5 - recentUsage.length);
      } else {
        // 새 사용자의 경우 문서 생성
        await setDoc(userDocRef, {
          membershipType: 'Free',
          premiumCredits: 0,
          usageHistory: []
        });
        setRemainingUses(5);
        setIsPro(false);
      }
    };

    if (auth.currentUser) {
      loadUserData();
    }
  }, [auth.currentUser]);

  /** -----------------
   *  무한 스크롤 관련
   * ----------------- */
  const throttledHandleScroll = useMemo(
    () =>
      throttle(() => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollPosition >= documentHeight * 0.8 && !isLoading && hasMore) {
          if (isSearching && searchRef.current?.value.trim()) {
            loadMoreSearchResults();
          } else {
            loadHistory();
          }
        }
      }, 300),
    [isLoading, hasMore, isSearching]
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      throttledHandleScroll.cancel();
    };
  }, [throttledHandleScroll]);

  /** -----------------
   *  Firestore 메시지 로드
   * ----------------- */
  const loadHistory = async (isFirstLoad = false) => {
    if (!auth.currentUser || isLoading || (!hasMore && !isFirstLoad)) return;

    try {
      setIsLoading(true);
      let q = query(
        collection(db, 'messages'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('timestamp', 'desc'),
        limit(10)
      );

      if (lastDoc && !isFirstLoad) {
        q = query(q, startAfter(lastDoc));
      }

      const querySnapshot = await getDocs(q);
      const newMessages = querySnapshot.docs.map((docSnap) => ({
        ...docSnap.data(),
        documentId: docSnap.id
      }));

      setMessages((prev) =>
        isFirstLoad ? newMessages : [...prev, ...newMessages]
      );
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 10);
    } catch (error) {
      console.error('Failed to load history:', error);
      alert('Failed to load chat history.');
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreSearchResults = async () => {
    if (isLoading || !hasMore) return;

    try {
      setIsLoading(true);
      let q;

      if (currentSearchTerm && lastDoc) {
        q = query(
          collection(db, 'messages'),
          where('userId', '==', auth.currentUser.uid),
          where('searchKeywords', 'array-contains', currentSearchTerm),
          orderBy('timestamp', 'desc'),
          startAfter(lastDoc),
          limit(10)
        );
      }

      const querySnapshot = await getDocs(q);
      const newMessages = querySnapshot.docs.map((docSnap) => ({
        documentId: docSnap.id,
        ...docSnap.data()
      }));

      setSearchResults((prev) => [...prev, ...newMessages]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === 10);
    } catch (error) {
      console.error('Failed to load additional messages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  /** -----------------
   *  검색 기능
   * ----------------- */
  const handleSearch = async () => {
    const term = searchRef.current?.value.trim().toLowerCase() || '';
    setIsSearching(!!term);
    setCurrentSearchTerm(term);

    try {
      setIsLoading(true);

      if (term) {
        // 검색 모드
        let q = query(
          collection(db, 'messages'),
          where('userId', '==', auth.currentUser.uid),
          where('searchKeywords', 'array-contains', term),
          orderBy('timestamp', 'desc'),
          limit(10)
        );

        const querySnapshot = await getDocs(q);
        const msgs = querySnapshot.docs.map((docSnap) => ({
          documentId: docSnap.id,
          ...docSnap.data()
        }));

        // 검색 결과 초기화
        setSearchResults(msgs);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === 10);
      } else {
        // 검색어가 없으면 일반 모드 복귀
        setSearchResults([]);
        setLastDoc(null);
        await loadHistory(true);
      }
    } catch (error) {
      console.error('Search failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  /** -----------------
   *  메시지 삭제
   * ----------------- */
  const handleDeleteClick = async (message) => {
    const isShared = await checkIfMessageShared(message);
    setIsSharedMessage(isShared);
    setMessageToDelete(message);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    setDeleteDialogOpen(false);
    setMessageToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    if (!messageToDelete) return;
    try {
      await deleteDoc(doc(db, 'messages', messageToDelete.documentId));

      if (isSharedMessage) {
        const q = query(
          collection(db, 'shared_messages'),
          where('userId', '==', auth.currentUser.uid),
          where('sharedId', '==', messageToDelete.documentId)
        );
        const querySnapshot = await getDocs(q);
        const deletePromises = querySnapshot.docs.map((docSnap) =>
          deleteDoc(docSnap.ref)
        );
        await Promise.all(deletePromises);
      }

      setMessages((prev) =>
        prev.filter((msg) => msg.documentId !== messageToDelete.documentId)
      );
      setDeleteDialogOpen(false);
      setMessageToDelete(null);
      setIsSharedMessage(false);
    } catch (error) {
      console.error('Failed to delete message:', error);
      alert('Failed to delete message.');
    }
  };

  const checkIfMessageShared = async (message) => {
    try {
      const q = query(
        collection(db, 'shared_messages'),
        where('userId', '==', auth.currentUser.uid),
        where('sharedId', '==', message.documentId)
      );
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Failed to check share status:', error);
      return false;
    }
  };

  /** -----------------
   *  메시지 공유
   * ----------------- */
  const handleShare = async (message) => {
    try {
      setSharingMessageId(message.documentId);
      const isAlreadyShared = await checkIfMessageShared(message);
      if (isAlreadyShared) {
        alert('This message has already been shared to the community.');
        return;
      }

      const sharedId = message.documentId;
      const userDisplayName =
        auth.currentUser.displayName || auth.currentUser.email.split('@')[0];
      const searchKeywords = [
        ...generateSearchKeywords(message.question),
        userDisplayName.toLowerCase(),
        auth.currentUser.email.split('@')[0].toLowerCase()
      ];

      await addDoc(collection(db, 'shared_messages'), {
        sharedId,
        question: message.question,
        answer: message.answer,
        userEmail: auth.currentUser.email,
        userId: auth.currentUser.uid,
        userDisplayName,
        sharedAt: new Date().toISOString(),
        simulationOptions: message.simulationOptions || [],
        isVoteSimulation: true,
        searchKeywords: [...new Set(searchKeywords)]
      });
      alert('Successfully shared to the community!');
    } catch (error) {
      console.error('Share failed:', error);
      alert('An error occurred while sharing.');
    } finally {
      setSharingMessageId(null);
    }
  };

  /** -----------------
   *  시뮬레이션 로직
   * ----------------- */
  const generateRandomPerson = () => ({
    gender: 'random',
    age: 'random',
    job: 'random',
    mbti: 'random'
  });

  const handleCheckboxChange = (index, event) => {
    const isShiftPressed = event?.nativeEvent?.shiftKey;

    if (isShiftPressed && lastCheckedIndex !== null) {
      const start = Math.min(lastCheckedIndex, index);
      const end = Math.max(lastCheckedIndex, index);
      const rangeIndexes = Array.from({ length: end - start + 1 }, (_, i) => start + i);

      const newSelected = Array.from(new Set([...selectedPeople, ...rangeIndexes]));
      setSelectedPeople(newSelected);
    } else {
      if (selectedPeople.length === 0) {
        setSelectedPeople([index]);
        setSourceIndex(index);
      } else if (index === sourceIndex) {
        setSelectedPeople([]);
        setSourceIndex(null);
      } else if (selectedPeople.includes(index)) {
        setSelectedPeople(selectedPeople.filter((i) => i !== index));
      } else {
        setSelectedPeople([...selectedPeople, index]);
      }
    }
    setLastCheckedIndex(index);
  };

  const handleCopyAttributes = () => {
    if (sourceIndex !== null && selectedPeople.length > 1) {
      const sourcePerson = people[sourceIndex];
      const newPeople = [...people];
      selectedPeople
        .filter((idx) => idx !== sourceIndex)
        .forEach((idx) => {
          newPeople[idx] = { ...sourcePerson };
        });
      setPeople(newPeople);

      setTimeout(() => {
        setSelectedPeople([]);
        setSourceIndex(null);
      }, 50);
    }
  };

  const handlePeopleSubmit = () => {
    setStep(6);
    setIsSimulationReady(true);
    const displayPrompt = `I asked ${peopleCount} people with diverse backgrounds and perspectives about the agenda "${agenda}".`;
    setGeneratedPrompt(displayPrompt);
    setInput(displayPrompt);
  };

  const handleStepEdit = (stepNumber) => {
    setStep(stepNumber);
    setIsSimulationReady(false);

    // refs 초기화
    if (agendaRef.current) agendaRef.current.value = agenda;
    if (optionCountRef.current) optionCountRef.current.value = optionCount;
    if (peopleCountRef.current) peopleCountRef.current.value = peopleCount;

    // 단계별 상태 일부 초기화
    if (stepNumber <= 1) {
      setAgenda('');
      setOptionCount('');
      setOptions([]);
      setPeopleCount('');
      setPeople([]);
    } else if (stepNumber <= 2) {
      setOptionCount('');
      setOptions([]);
      setPeopleCount('');
      setPeople([]);
    } else if (stepNumber <= 3) {
      setPeopleCount('');
      setPeople([]);
    } else if (stepNumber <= 4) {
      setPeople([]);
    }

    setInput('');
    setGeneratedPrompt('');
  };

  /** -----------------
   *  최종 시뮬레이션 결과 요청
   * ----------------- */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!auth.currentUser?.emailVerified) {
      alert('Please verify your email to use this service');
      return;
    }
    if (!input.trim() || isSimulationLoading || !isSimulationReady) return;

    setIsSimulationLoading(true);
    try {
      const token = await auth.currentUser.getIdToken();
      const response = await axios.post(
        'https://us-central1-votesim-a2fba.cloudfunctions.net/chatGptProxy',
        {
          agenda,
          peopleCount,
          people,
          options,
          isPremium: usePremium
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // 응답 처리
      const gptResponse = response.data.choices[0].message.content;
      const messageData = {
        userId: auth.currentUser.uid,
        question: input,
        answer: gptResponse,
        timestamp: new Date().toISOString(),
        simulationOptions: [...options],
        isVoteSimulation: true,
        isPremium: usePremium,
        searchKeywords: generateSearchKeywords(input)
      };

      const docRef = await addDoc(collection(db, 'messages'), messageData);
      const newMessage = { ...messageData, documentId: docRef.id };
      setMessages((prev) => [newMessage, ...prev]);

      // 사용량 업데이트
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();
      if (usePremium) {
        // 프리미엄 크레딧 업데이트
        setPremiumCredits(userData.premiumCredits || 0);
      } else {
        // 일반 사용량 업데이트
        const now = new Date();
        const usageHistory = userData.usageHistory || [];
        const recentUsage = usageHistory.filter((usage) => {
          const usageTime = new Date(usage);
          return now - usageTime < 24 * 60 * 60 * 1000;
        });
        setRemainingUses(5 - recentUsage.length);
      }
    } catch (error) {
      if (error.response?.status === 429) {
        // 사용량 초과 에러 처리
        const nextAvailable = new Date(error.response.data.nextAvailableTime);
        alert(
          `Daily usage limit exceeded.\nNext available time: ${nextAvailable.toLocaleString()}`
        );
        setShowPremiumDialog(true);
      } else if (error.response?.status === 403) {
        if (error.response.data.membershipExpired) {
          alert('Your premium membership has expired.');
          navigate('/pricing');
        } else if (error.response.data.insufficientCredits) {
          alert('Insufficient premium credits.');
          navigate('/pricing');
        }
      } else {
        console.error('Error:', error);
        alert('An error occurred while sending the message.');
      }
    } finally {
      setIsSimulationLoading(false);
    }
  };

  /** -----------------
   *  검색 키워드 생성
   * ----------------- */
  const generateSearchKeywords = (text) => {
    const normalized = text.toLowerCase();
    const words = normalized.replace(/[^\w\s가-힣]/g, ' ').split(/\s+/);

    const userDisplayName =
      auth.currentUser?.displayName?.toLowerCase() || '';
    const userEmail =
      auth.currentUser?.email?.split('@')[0].toLowerCase() || '';

    return [...new Set([...words, userDisplayName, userEmail])].filter(
      (word) => word
    );
  };

  /** -----------------
   *  usePremium 변경 시 (시뮬레이션 값 초기화)
   * ----------------- */
  useEffect(() => {
    // Premium toggle 시 초기화
    setStep(1);
    setAgenda('');
    setOptionCount('');
    setOptions([]);
    setPeopleCount('');
    setPeople([]);
    setIsSimulationReady(false);
    setGeneratedPrompt('');
    setInput('');

    if (agendaRef.current) agendaRef.current.value = '';
    if (optionCountRef.current) optionCountRef.current.value = '';
    if (peopleCountRef.current) peopleCountRef.current.value = '';
  }, [usePremium]);

  /** -----------------
   *  스텝 별 완료된 정보 표시
   * ----------------- */
  const renderCompletedStep = (stepNumber) => {
    switch (stepNumber) {
      case 1:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Agenda
                </Typography>
                <Typography>{agenda}</Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleStepEdit(1)}
                sx={{ ml: 2 }}
              >
                Edit
              </Button>
            </Box>
          </Paper>
        );
      case 2:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Number of Options
                </Typography>
                <Typography>{optionCount} options</Typography>
              </Box>
              {usePremium && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleStepEdit(2)}
                  sx={{ ml: 2 }}
                >
                  Edit
                </Button>
              )}
            </Box>
          </Paper>
        );
      case 3:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Answer Options
                </Typography>
                {options.map((option, index) => (
                  <Typography key={index}>
                    {index + 1}. {option}
                  </Typography>
                ))}
              </Box>
              {usePremium && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleStepEdit(3)}
                  sx={{ ml: 2 }}
                >
                  Edit
                </Button>
              )}
            </Box>
          </Paper>
        );
      case 4:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Number of Participants
                </Typography>
                <Typography>{peopleCount} people</Typography>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleStepEdit(4)}
                sx={{ ml: 2 }}
              >
                Edit
              </Button>
            </Box>
          </Paper>
        );
      case 5:
        return (
          <Paper sx={{ p: 2, mb: 3, backgroundColor: '#f5f5f5' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
                  Participant Information
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gap: 2,
                    mb: 1
                  }}
                >
                  <Typography>Sex</Typography>
                  <Typography>Age</Typography>
                  <Typography>Job</Typography>
                  <Typography>MBTI</Typography>
                </Box>
                <Box>
                  {people.map((person, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gap: 2,
                        mb: 1
                      }}
                    >
                      <Typography>{person.gender}</Typography>
                      <Typography>
                        {person.age === 'random' ? 'random' : `${person.age}세`}
                      </Typography>
                      <Typography>{person.job}</Typography>
                      <Typography>{person.mbti}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleStepEdit(5)}
                sx={{ ml: 2 }}
              >
                Edit
              </Button>
            </Box>
          </Paper>
        );
      default:
        return null;
    }
  };

  /** -----------------
   *  랜더링
   * ----------------- */
  const displayedSearchResults = isSearching ? searchResults : messages;

  // usePremium이 false일 때, step 2와 3을 스킵하는 로직
  useEffect(() => {
    if (!usePremium && step === 2) {
      setOptionCount('2');
      setOptions(['Yes', 'No']);
      setStep(4); // 옵션 부분 스킵
    }
  }, [step, usePremium]);

  const maxPeopleCount = usePremium ? 30 : 10;

  const handleToggleAnswer = (documentId) => {
    setExpandedAnswers((prev) => ({
      ...prev,
      [documentId]: !prev[documentId]
    }));
  };

  const handleCopyAnswer = (answer) => {
    navigator.clipboard.writeText(answer);
    alert('Copied!');
  };

  const currentUserDisplayName =
    auth.currentUser?.displayName || auth.currentUser?.email?.split('@')[0];

  return (
    <Box
      sx={{
        maxWidth: 800,
        margin: 'auto',
        mt: 5,
        p: 3,
        width: '100%',
        overflowX: 'hidden',
        boxSizing: 'border-box'
      }}
    >
      {/* 타이틀 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          mb: 3
        }}
      >
        <Typography variant="h4">Simulator</Typography>
        <Typography variant="body2" color="text.secondary">
          Signed in as {auth.currentUser?.email}
        </Typography>
      </Box>

      {/* 프리미엄 스위치 & 사용량 표시 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          mb: 3
        }}
      >
        <Box
          sx={{
            padding: '8px 16px',
            borderRadius: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
            border: '1px solid',
            borderColor: 'divider',
            minWidth: 'fit-content'
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={usePremium}
                onChange={(e) => {
                  if (!isPro) {
                    setShowPremiumDialog(true);
                    return;
                  }
                  setUsePremium(e.target.checked);
                }}
                color="primary"
              />
            }
            label={
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: '0.875rem'
                }}
              >
                {usePremium ? 'Premium' : 'Standard'}
              </Typography>
            }
            sx={{ margin: 0 }}
          />
        </Box>

        <Box sx={{ width: 'auto' }}>
          <DailyUsageCoins
            remainingUses={remainingUses}
            maxUses={5}
            isPro={isPro}
            premiumCredits={premiumCredits}
          />
        </Box>
      </Box>

      {/* 이미 완료된 Step들 표시 */}
      {Array.from({ length: step - 1 }, (_, i) => i + 1).map((stepNum) => (
        <div key={`completed-step-${stepNum}`}>
          {renderCompletedStep(stepNum)}
        </div>
      ))}

      {/* Step 1: Agenda */}
      {step === 1 && (
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            placeholder="Set agenda (e.g., Should AI be regulated?)"
            inputRef={agendaRef}
            defaultValue={agenda}
            inputProps={{ maxLength: 80 }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            onClick={() => {
              const newAgenda = agendaRef.current?.value || '';
              if (newAgenda.trim()) {
                setAgenda(newAgenda);
                setStep(2);
              }
            }}
          >
            Confirm
          </Button>
        </Box>
      )}

      {/* Step 2: 옵션 개수 (Premium) */}
      {step === 2 && usePremium && (
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            type="number"
            label="Number of options (Max 4 / Default Yes:No if empty)"
            inputRef={optionCountRef}
            defaultValue={optionCount}
            sx={{ mb: 2 }}
            inputProps={{
              min: 1,
              max: 4,
              style: { textAlign: 'center' }
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              const count = parseInt(optionCountRef.current?.value || '0', 10);
              if (!count) {
                setOptionCount('2');
                setOptions(['Yes', 'No']);
                setStep(4);
              } else if (count > 0 && count <= 4) {
                setOptionCount(count.toString());
                setOptions(Array(count).fill(''));
                setStep(3);
              }
            }}
          >
            Confirm
          </Button>
        </Box>
      )}

      {/* Step 3: 옵션 텍스트 입력 (Premium) */}
      {step === 3 && usePremium && (
        <Box sx={{ mb: 3 }}>
          {Array(parseInt(optionCount, 10))
            .fill(null)
            .map((_, index) => (
              <TextField
                key={index}
                fullWidth
                label={`Option ${index + 1}`}
                defaultValue={options[index]}
                inputRef={(el) => (optionsRef.current[index] = el)}
                sx={{ mb: 2 }}
              />
            ))}
          <Button
            variant="contained"
            onClick={() => {
              const newOptions = Array(parseInt(optionCount, 10))
                .fill(null)
                .map((_, i) => optionsRef.current[i]?.value || '');
              if (newOptions.every((o) => o.trim())) {
                setOptions(newOptions);
                setStep(4);
              }
            }}
          >
            Confirm
          </Button>
        </Box>
      )}

      {/* Step 4: 참여 인원수 */}
      {step === 4 && (
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            type="number"
            label={`Number of participants (Max ${maxPeopleCount})`}
            inputRef={peopleCountRef}
            defaultValue={peopleCount}
            sx={{ mb: 2 }}
            inputProps={{
              min: 1,
              max: maxPeopleCount,
              style: { textAlign: 'center' }
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              const count = parseInt(peopleCountRef.current?.value || '0', 10);
              if (count > 0 && count <= maxPeopleCount) {
                // 사람 정보 랜덤 생성
                setPeopleCount(count.toString());
                setPeople(
                  Array(count)
                    .fill(null)
                    .map(() => generateRandomPerson())
                );
                setStep(5);
              }
            }}
          >
            Confirm
          </Button>
        </Box>
      )}

      {/* Step 5: 사람 정보 수정 */}
      {step === 5 && (
        <Box>
          {selectedPeople.length > 1 && (
            <Box
              sx={{
                position: 'fixed',
                bottom: '2rem',
                right: '2rem',
                zIndex: 1000,
                animation: 'fadeIn 0.3s ease-in-out',
                '@keyframes fadeIn': {
                  '0%': {
                    opacity: 0,
                    transform: 'translateY(20px)'
                  },
                  '100%': {
                    opacity: 1,
                    transform: 'translateY(0)'
                  }
                }
              }}
            >
              <Button
                variant="contained"
                onClick={handleCopyAttributes}
                sx={{
                  boxShadow: 3,
                  borderRadius: '28px',
                  px: 3,
                  py: 1.5,
                  bgcolor: 'primary.main',
                  '&:hover': {
                    bgcolor: 'primary.dark'
                  }
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>Copy attributes to selected</Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '0.55rem',
                      opacity: 0.8
                    }}
                  >
                    (Shift+checkbox) for range selection
                  </Typography>
                </Box>
              </Button>
            </Box>
          )}

          <Paper sx={{ p: 2, mb: 2, bgcolor: 'primary.main', color: 'white' }}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '40px 50px repeat(4, 1fr)',
                gap: 2,
                alignItems: 'center'
              }}
            >
              <Box>No.</Box>
              <Box>Select</Box>
              <Typography>Sex</Typography>
              <Typography>Age</Typography>
              <Typography>Job</Typography>
              <Typography>MBTI</Typography>
            </Box>
          </Paper>

          {people.map((person, index) => (
            <Paper key={index} sx={{ p: 2, mb: 2 }}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '40px 50px repeat(4, 1fr)',
                  gap: 2,
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{ textAlign: 'center', fontWeight: 'medium', color: 'text.secondary' }}
                >
                  {index + 1}
                </Typography>
                <Checkbox
                  checked={selectedPeople.includes(index)}
                  onChange={(event) => handleCheckboxChange(index, event)}
                  sx={{
                    color: index === sourceIndex ? 'primary.main' : 'error.main',
                    '&.Mui-checked': {
                      color: index === sourceIndex ? 'primary.main' : 'error.main'
                    }
                  }}
                />
                <FormControl fullWidth>
                  <Select
                    value={person.gender}
                    onChange={(e) => {
                      const newPeople = [...people];
                      newPeople[index] = {
                        ...newPeople[index],
                        gender: e.target.value
                      };
                      setPeople(newPeople);
                    }}
                  >
                    <MenuItem value="random">Random</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <Select
                    value={person.age}
                    onChange={(e) => {
                      const newPeople = [...people];
                      newPeople[index] = {
                        ...newPeople[index],
                        age: e.target.value
                      };
                      setPeople(newPeople);
                    }}
                  >
                    <MenuItem value="random">Random</MenuItem>
                    {Array.from({ length: 81 }, (_, i) => i + 10).map((age) => (
                      <MenuItem key={age} value={age}>
                        {age} age
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <Select
                    value={person.job}
                    onChange={(e) => {
                      const newPeople = [...people];
                      newPeople[index] = {
                        ...newPeople[index],
                        job: e.target.value
                      };
                      setPeople(newPeople);
                    }}
                    disabled={!usePremium}
                  >
                    <MenuItem value="random">Random</MenuItem>
                    <MenuItem value="Employee">Employee</MenuItem>
                    <MenuItem value="Student">Student</MenuItem>
                    <MenuItem value="Self-employed">Self-employed</MenuItem>
                    <MenuItem value="Civil Servant">Civil Servant</MenuItem>
                    <MenuItem value="Professional">Professional</MenuItem>
                    <MenuItem value="Daily Worker">Daily Worker</MenuItem>
                    <MenuItem value="Homemaker">Homemaker</MenuItem>
                    <MenuItem value="Unemployed">Unemployed</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <Select
                    value={person.mbti}
                    onChange={(e) => {
                      const newPeople = [...people];
                      newPeople[index] = {
                        ...newPeople[index],
                        mbti: e.target.value
                      };
                      setPeople(newPeople);
                    }}
                    disabled={!usePremium}
                  >
                    <MenuItem value="random">Random</MenuItem>
                    {[
                      'INTJ','INTP','ENTJ','ENTP',
                      'INFJ','INFP','ENFJ','ENFP',
                      'ISTJ','ISFJ','ESTJ','ESFJ',
                      'ISTP','ISFP','ESTP','ESFP'
                    ].map((mbti) => (
                      <MenuItem key={mbti} value={mbti}>
                        {mbti}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          ))}

          <Button variant="contained" onClick={handlePeopleSubmit} sx={{ mt: 2 }} fullWidth>
            Confirm
          </Button>
        </Box>
      )}

      {/* 실제 요청/결과 확인 폼 */}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          multiline
          rows={3}
          value={input}
          placeholder={
            !auth.currentUser
              ? 'Please Sign in to use this service'
              : isSimulationReady
              ? 'Check simulation results'
              : 'Please complete simulation settings first'
          }
          disabled={true} // 최종 입력 대신, 단계 완료 시 자동 세팅
          sx={{
            backgroundColor: !isSimulationReady ? '#f5f5f5' : 'white',
            mt: 3,
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              opacity: 1
            }
          }}
        />
        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ mt: 2 }}
          disabled={
            !auth.currentUser?.emailVerified ||
            !isSimulationReady ||
            isSimulationLoading
          }
        >
          {!auth.currentUser
            ? 'Please Sign in to use this service'
            : !auth.currentUser.emailVerified
            ? 'Please verify your email to use this service'
            : isSimulationLoading
            ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                <span>Processing...</span>
              </Box>
              )
            : 'View Results'}
        </Button>
      </form>

      {/* 검색 UI */}
      <Box sx={{ display: 'flex', gap: 1, mt: 2, mb: 3, alignItems: 'center' }}>
        <TextField
          fullWidth
          size="small"
          placeholder="Search messages..."
          inputRef={searchRef}
          defaultValue=""
        />
        <Button variant="outlined" onClick={handleSearch}>
          Search
        </Button>
      </Box>

      {/* 메시지 목록 */}
      <Box sx={{ mt: 3 }}>
        {isSearching ? (
          // 검색 모드
          searchResults.length === 0 ? (
            <Typography
              sx={{ textAlign: 'center', color: 'text.secondary', my: 2 }}
            >
              No results found.
            </Typography>
          ) : (
            <>
              {searchResults.map((message) => (
                <MessageItem
                  key={message.documentId}
                  message={message}
                  expanded={expandedAnswers[message.documentId] || false}
                  onToggleAnswer={handleToggleAnswer}
                  onCopyAnswer={handleCopyAnswer}
                  onDeleteClick={handleDeleteClick}
                  onShare={handleShare}
                  sharingMessageId={sharingMessageId}
                  isOwner={true} // 본인의 메시지만 나오므로
                  currentUserDisplayName={currentUserDisplayName}
                />
              ))}
              {!searchHasMore && searchResults.length > 0 && (
                <Typography
                  sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}
                >
                  All messages have been loaded.
                </Typography>
              )}
            </>
          )
        ) : (
          // 일반 모드
          <>
            {messages.map((message) => (
              <MessageItem
                key={message.documentId}
                message={message}
                expanded={expandedAnswers[message.documentId] || false}
                onToggleAnswer={handleToggleAnswer}
                onCopyAnswer={handleCopyAnswer}
                onDeleteClick={handleDeleteClick}
                onShare={handleShare}
                sharingMessageId={sharingMessageId}
                isOwner // 본인 메시지이므로 true
                currentUserDisplayName={currentUserDisplayName}
              />
            ))}
            {!hasMore && messages.length > 0 && (
              <Typography
                sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}
              >
                All messages have been loaded.
              </Typography>
            )}
          </>
        )}
      </Box>

      {/* 메시지 삭제 모달 */}
      <DeleteConfirmDialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
        isSharedMessage={isSharedMessage}
        messageToDelete={messageToDelete}
      />

      {/* 프리미엄 모달 */}
      {showPremiumDialog && (
        <PremiumDialog open={showPremiumDialog} onClose={() => setShowPremiumDialog(false)} />
      )}
    </Box>
  );
}

export default Chat;
