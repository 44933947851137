// Privacy.js
import React from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PrivacyPolicy = () => {
  const renderContent = (content) => {
    return content.split('\n').map((line, i) => (
      <Typography key={i} paragraph>
        {line.trim()}
      </Typography>
    ));
  };

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
          Privacy Policy
        </Typography>
        
        <Typography paragraph color="text.secondary" sx={{ mb: 4 }}>
          VotSim (hereinafter referred to as "the Company") has established the following policy to protect users' personal information and rights in accordance with the Personal Information Protection Act and to efficiently handle user grievances related to personal information.
        </Typography>

        {[
          {
            title: "1. Purpose of Processing Personal Information",
            content: `The company processes personal information for the following purposes and does not use it for any other purposes:
            - Member registration and management
            - Service provision and contract fulfillment
            - Payment processing and settlement
            - Marketing and advertising utilization
            - Storage and sharing of voting simulation results
            - Community service provision and management`
          },
          {
            title: "2. Processing and Retention Period of Personal Information",
            content: `The company processes and retains personal information within the period of retention and use of personal information in accordance with laws or within the period of retention and use of personal information agreed upon when collecting personal information from the data subject.
            - Membership information: Until membership withdrawal
            - Payment information: Retention period in accordance with relevant laws
            - Simulation data: During the service provision period`
          },
          {
            title: "3. Provision of Personal Information to Third Parties",
            content: `The company does not provide users' personal information to third parties in principle. However, the following cases are exceptions:
            - When the user has explicitly consented
            - When required by law or when requested by investigative agencies in accordance with procedures and methods specified by law for investigation purposes`
          },
          {
            title: "4. Rights and Obligations of Users and How to Exercise Them",
            content: `Users can exercise the following rights as personal information subjects:
            - Request to view personal information
            - Request for correction in case of errors
            - Request for deletion
            - Request to stop processing`
          },
          {
            title: "5. Personal Information Items Being Processed",
            content: `The company processes the following personal information items:
            - Required items: Email address, password
            - Optional items: Profile information
            - Automatically collected items: IP address, cookies, service usage records`
          },
          {
            title: "6. Destruction of Personal Information",
            content: `The company destroys personal information without delay when it becomes unnecessary due to the expiration of the retention period or achievement of the processing purpose.`
          },
        ].map((section, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" fontWeight="bold">
                {section.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderContent(section.content)}
            </AccordionDetails>
          </Accordion>
        ))}
      </Paper>
    </Container>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
};

export default PrivacyPolicy;