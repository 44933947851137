import React, { useState } from 'react';
import { auth } from '../firebase';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, TextField, Button, Typography, Alert, Divider } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [showResendButton, setShowResendButton] = useState(false);
  const [resendEmail, setResendEmail] = useState('');

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      if (!userCredential.user.emailVerified) {
        setError('Email verification required. Please check your inbox.');
        setShowResendButton(true);
        setResendEmail(email);
        return;
      }

      const from = location.state?.from || '/chat';
      navigate(from);
    } catch (error) {
      setError('Sign in failed: ' + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      const userDocRef = doc(db, 'users', result.user.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          displayName: result.user.displayName || 'User',
          email: result.user.email,
          joinDate: result.user.metadata.creationTime,
          membershipType: 'Free',
          premiumCredits: 0,
          usageHistory: []
        });
      }

      const from = location.state?.from || '/chat';
      navigate(from);
    } catch (error) {
      console.error('Google Sign in error:', error);
      setError('Google Sign in failed: ' + error.message);
    }
  };

  const handleSignUp = async () => {
    setError('');

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 6 characters long.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      await sendEmailVerification(userCredential.user);
      
      setError('Verification email sent. Please check your inbox.');
    } catch (error) {
      setError('Sign up failed: ' + error.message);
    }
  };

  const handleResendVerification = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, resendEmail, password);
      await sendEmailVerification(userCredential.user);
      setError('Verification email resent. Please check your inbox.');
      setShowResendButton(false);
    } catch (error) {
      setError('Failed to resend verification email: ' + error.message);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', mt: 5, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Sign in
      </Typography>
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          action={
            showResendButton && (
              <Button
                variant="contained"
                size="small"
                onClick={handleResendVerification}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  color: '#d32f2f',
                  border: '1px solid currentColor',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    border: '1px solid #d32f2f'
                  }
                }}
              >
                Resend Verification Email
              </Button>
            )
          }
        >
          {error}
        </Alert>
      )}
      <form onSubmit={handleLogin}>
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error && error.includes('email')}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!error && error.includes('password')}
        />
        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{ mt: 2 }}
        >
          Sign in
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleSignUp}
          sx={{ mt: 1 }}
        >
          Sign Up
        </Button>
      </form>

      <Divider sx={{ my: 3 }}>or</Divider>
        
      <Button
        fullWidth
        variant="outlined"
        onClick={handleGoogleLogin}
        startIcon={<GoogleIcon />}
        sx={{ 
          mt: 1,
          borderColor: '#4285f4',
          color: '#4285f4',
          '&:hover': {
            borderColor: '#4285f4',
            backgroundColor: 'rgba(66, 133, 244, 0.04)'
          }
        }}
      >
        Sign in with Google
      </Button>
    </Box>
  );
}

export default Login;