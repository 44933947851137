import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper, Typography, Grid, Button } from '@mui/material';
import { Camera } from 'lucide-react';
import { createPortal } from 'react-dom';
import { auth, db } from '../firebase';
import { doc, updateDoc, increment, setDoc } from 'firebase/firestore';
import { useTheme, useMediaQuery } from '@mui/material';

const parseGptResponse = (response) => {
  try {
    const lines = response.split('\n');
    const results = [];
    
    lines.forEach((line, index) => {
      if (line.includes('Gender:')) {  // 'ㅣ' 제거
        const infoMatch = line.match(/Gender: ([^|ㅣ]+).*Age: ([^|ㅣ]+).*(?:Job|Occupation): ([^|ㅣ]+).*MBTI: ([^|ㅣ]+)/);
        
        let answerMatch = null;
        let reasonMatch = null;

        for (let i = index + 1; i < lines.length && i <= index + 3; i++) {
          const currentLine = lines[i];
          if (currentLine.includes('Response:')) {  // Answer -> Response
            answerMatch = currentLine.match(/Response: (\d+)/);
          }
          if (currentLine.includes('Reason:')) {
            reasonMatch = currentLine.match(/Reason:\s*"([^"]+)"/);
          }
        }

        if (infoMatch && answerMatch) {
          let choice = parseInt(answerMatch[1]);
          results.push({
            gender: infoMatch[1].trim(),
            age: infoMatch[2].trim(),
            job: infoMatch[3].trim(),
            mbti: infoMatch[4].trim(),
            choice: choice,
            reason: reasonMatch ? reasonMatch[1] : 'No reason provided'
          });
        }
      }
    });
    
    return results;
  } catch (error) {
    console.error('Error parsing GPT response:', error);
    return [];
  }
};

const VoterInfoPortal = ({ voter, position, setHoveredPerson }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(hover: none)');

  if (!voter) return null;

  return createPortal(
    <Paper
      sx={{
        position: 'fixed',
        zIndex: 9999,
        width: isMobile ? '90%' : 250,
        p: 2,
        ...(isMobile ? {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80vh',
          overflowY: 'auto',
        } : {
          top: position.y + 40,
          left: position.x,
          transform: 'translateX(-50%)',
        }),
        boxShadow: 3,
        backgroundColor: 'background.paper',
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 'bold' }}>
        Voter Information
      </Typography>
      <Typography variant="body2">Gender: {voter.gender}</Typography>
      <Typography variant="body2">Age: {voter.age}</Typography>
      <Typography variant="body2">Occupation: {voter.job}</Typography>
      <Typography variant="body2">MBTI: {voter.mbti}</Typography>
      <Typography variant="subtitle2" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>
        Reason for Choice
      </Typography>
      <Typography variant="body2">{voter.reason}</Typography>
      
      {isMobile && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            onClick={() => setHoveredPerson(null)}
            sx={{ minWidth: 100 }}
          >
            OK
          </Button>
        </Box>
      )}
    </Paper>,
    document.body
  );
};

const drawVoterDot = (ctx, x, y, size, voter) => {
  ctx.beginPath();
  ctx.arc(x + size/2, y + size/2, size/2, 0, Math.PI * 2);
  ctx.fillStyle = ['male', 'man', 'm'].includes(voter.gender.trim().toLowerCase()) ? '#1976d2' : '#dc004e';
  ctx.fill();

  ctx.fillStyle = '#FFFFFF';
  ctx.font = `bold ${size/2}px Arial`;
  ctx.textAlign = 'center';
  const genderText = ['male', 'man', 'm'].includes(voter.gender.trim().toLowerCase()) ? 'M' : 'W';
  ctx.fillText(genderText, x + size/2, y + size/1.8 - size/8);
  
  ctx.font = `bold ${size/2.8}px Arial`;
  ctx.fillText(voter.age.replace(/[^0-9]/g, ''), x + size/2, y + size/2 + size/3);
};

const VoteVisualization = ({ gptResponse, options, agenda, displayName }) => {
  const [parsedData, setParsedData] = useState([]);
  const [hoveredPerson, setHoveredPerson] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState(null);
  const visualizationRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery('(hover: none)');

  useEffect(() => {
    const parsed = parseGptResponse(gptResponse);
    setParsedData(parsed);
  }, [gptResponse]);

  const handleDotInteraction = (voter, event) => {
    if (isMobile) {
      const rect = event.currentTarget.getBoundingClientRect();
      setPopoverPosition({
        x: window.innerWidth / 2,
        y: window.innerHeight / 2
      });
      setHoveredPerson(voter);
    } else {
      const rect = event.currentTarget.getBoundingClientRect();
      setPopoverPosition({
        x: rect.left + (rect.width / 2),
        y: rect.top
      });
      setHoveredPerson(voter);
    }
  };

  const saveAsImage = async () => {
    if (!visualizationRef.current) return;

    if (!auth.currentUser) {
      alert('Please Sign in to save images.');
      return;
    }

    try {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDocRef, {
        totalImageSaves: increment(1)
      });

      const statsDocRef = doc(db, 'statistics', 'global');
      
      await setDoc(statsDocRef, {
        totalImageSaves: increment(1)
      }, { merge: true });

      const canvas = document.createElement('canvas');
      const size = 1080;
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d');
      const padding = size * 0.05;

      ctx.fillStyle = '#2A3942';
      ctx.fillRect(0, 0, size, size);

      ctx.fillStyle = '#FFFFFF';
      const maxWidth = size - 100;
      let fontSize = 56;
      
      do {
        ctx.font = `bold ${fontSize}px Arial`;
        const metrics = ctx.measureText(agenda);
        if (metrics.width <= maxWidth) break;
        fontSize -= 2;
      } while (fontSize > 20);
      
      ctx.textAlign = 'center';
      ctx.fillText(agenda, size/2, padding + 20);

      ctx.font = '20px Arial';
      ctx.fillStyle = '#CCCCCC';
      ctx.fillText(`By. ${displayName}`, size/2, padding + 70);

      const groupedVoters = {};
      options.forEach((_, index) => {
        groupedVoters[index + 1] = parsedData.filter(p => p.choice === index + 1);
      });

      const columns = options.length > 4 ? 3 : 2;
      const rows = Math.ceil(options.length / columns);
      const cellWidth = (size - (padding * 2)) / columns;
      const cellHeight = (size - (padding * 6)) / rows;

      options.forEach((option, index) => {
        const row = Math.floor(index / columns);
        const col = index % columns;
        const x = padding + (col * cellWidth);
        const y = padding * 3 + (row * cellHeight);

        const postItX = x + 10;
        const postItY = y + 10;
        const postItWidth = cellWidth - 20;
        const postItHeight = cellHeight - 20;
        const foldSize = 80;

        ctx.fillStyle = '#fff8b8';
        ctx.beginPath();
        ctx.moveTo(postItX, postItY);
        ctx.lineTo(postItX + postItWidth, postItY);
        ctx.lineTo(postItX + postItWidth, postItY + postItHeight);
        ctx.lineTo(postItX + foldSize, postItY + postItHeight);
        ctx.lineTo(postItX, postItY + postItHeight - foldSize);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = '#e8e1a7';
        ctx.beginPath();
        ctx.moveTo(postItX, postItY + postItHeight - foldSize);
        ctx.lineTo(postItX + foldSize, postItY + postItHeight);
        ctx.lineTo(postItX + foldSize, postItY + postItHeight - foldSize);
        ctx.closePath();
        ctx.fill();

        ctx.fillStyle = '#000000';
        ctx.font = 'bold 48px Arial';
        ctx.textAlign = 'center';
        ctx.fillText(option, x + cellWidth/2, y + 70);

        const voters = groupedVoters[index + 1] || [];
        const dotSize = 50;
        const dotsPerRow = Math.floor((cellWidth - 40) / (dotSize + 5));

        voters.forEach((voter, voterIndex) => {
          const dotRow = Math.floor(voterIndex / dotsPerRow);
          const dotCol = voterIndex % dotsPerRow;
          const dotX = x + 20 + (dotCol * (dotSize + 5));
          const dotY = y + 100 + (dotRow * (dotSize + 5));

          drawVoterDot(ctx, dotX, dotY, dotSize, voter);
        });

        ctx.fillStyle = '#666666';
        ctx.font = '16px Arial';
        ctx.textAlign = 'right';
        ctx.fillText(`Total: ${voters.length}`, x + cellWidth - 20, y + cellHeight - 20);
      });

      ctx.fillStyle = '#FFFFFF';
      ctx.font = '48px Arial';
      ctx.textAlign = 'center';
      ctx.fillText('VoteSimulator.com', size/2, size - 40);

      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = agenda + '_' + new Date().toLocaleDateString() + '.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error saving image:', error);
      alert('Unable to save image. Please check your authentication status!');
    }
  };

  const groupedVoters = {};
  options.forEach((_, index) => {
    groupedVoters[index + 1] = parsedData.filter(p => p.choice === index + 1);
  });

  if (!options || options.length === 0) {
    return <Typography>Unable to load data.</Typography>;
  }

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden'
    }}>
      <Box 
        ref={visualizationRef}
        sx={{ 
          width: '100%',
          maxWidth: '660px',
          bgcolor: '#2A3942',
          borderRadius: 1,
          boxShadow: 1,
          p: 2
        }}
      >
        <Grid 
          container 
          spacing={1}
          sx={{
            width: '100%',
            m: 0,
            justifyContent: 'center'
          }}
        >
          {options.map((option, index) => (
            <Grid 
              item 
              xs={12} 
              sm={6} 
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                height: '100%'
              }}
            >
              <Paper 
                elevation={3}
                sx={{ 
                  width: '100%',
                  maxWidth: '260px',
                  display: 'flex',
                  flexDirection: 'column',
                  p: 1,
                  bgcolor: '#fff8b8',
                  position: 'relative',
                  borderRadius: '0 !important',
                  height: '100%',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '40px',
                    height: '40px',
                    background: '#e8e1a7',
                    clipPath: 'polygon(0 0, 100% 100%, 0 100%)',
                    zIndex: 1
                  }
                }}
              >
                <Typography variant="h6" sx={{ mb: 1, color: '#000000' }}>
                  {option}
                </Typography>
                
                <Box sx={{ 
                  flex: 1,
                  bgcolor: '#fff8b8',
                  p: 1,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(32px, 1fr))',
                  gap: 0.5,
                  minHeight: '200px'
                }}>
                  {groupedVoters[index + 1]?.map((voter, voterIndex) => (
                    <Box
                      key={voterIndex}
                      sx={{ 
                        position: 'relative',
                        width: 32,
                        height: 32
                      }}
                      onClick={(e) => isMobile && handleDotInteraction(voter, e)}
                      onMouseEnter={(e) => !isMobile && handleDotInteraction(voter, e)}
                      onMouseLeave={() => !isMobile && setHoveredPerson(null)}
                    >
                      <Box
                        sx={{ 
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          bgcolor: ['male', 'man', 'm'].includes(voter.gender.trim().toLowerCase()) ? 'primary.main' : 'secondary.main',
                          color: 'white',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          fontSize: '0.75rem',
                          '&:hover': {
                            transform: 'scale(1.1)',
                            transition: 'transform 0.2s'
                          },
                          '@media (max-width: 600px)': {
                            '& > span:first-of-type': {
                              marginTop: '-5px'
                            },
                            '& > span:last-of-type': {
                              marginTop: '2px'
                            }
                          },
                          '@media (min-width: 601px) and (max-width: 1194px)': {
                            '@media (orientation: portrait)': {
                              '& > span:first-of-type': {
                                marginTop: '-4px'
                              },
                              '& > span:last-of-type': {
                                marginTop: '2px'
                              }
                            },
                            '@media (orientation: landscape)': {
                              '& > span:first-of-type': {
                                marginTop: '-5px'
                              },
                              '& > span:last-of-type': {
                                marginTop: '2px'
                              }
                            }
                          }
                        }}
                      >
                        <span>{['male', 'man', 'm'].includes(voter.gender.trim().toLowerCase()) ? '♂' : '♀'}</span>
                        <span style={{ fontSize: '0.8rem' }}>
                          {voter.age.replace(/[^0-9]/g, '')}
                        </span>
                      </Box>
                    </Box>
                  ))}
                </Box>
                
                <Typography sx={{ mt: 1, textAlign: 'right', color: 'text.secondary' }}>
                  Total: {groupedVoters[index + 1]?.length || 0}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ my: 2 }}>
        <Button
          variant="contained"
          onClick={saveAsImage}
          startIcon={<Camera size={20} />}
          sx={{
            bgcolor: 'primary.main',
            color: 'white',
            '&:hover': {
              bgcolor: 'primary.dark',
            }
          }}
        >
          Save as Image
        </Button>
      </Box>

      {hoveredPerson && popoverPosition && (
        <VoterInfoPortal 
          voter={hoveredPerson} 
          position={popoverPosition}
          setHoveredPerson={setHoveredPerson}
        />
      )}
    </Box>
  );
};

export default VoteVisualization;